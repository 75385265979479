import "../scss/app.scss";
import Vue from "vue";
import "bootstrap";
const InView = require("./InView");
const carousels = require("./carousels");
Vue.directive("scroll", {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  },
});
const app = new Vue({
  el: "#app",
  data: {
    isToggleMenu: false,
    isOpen: false,
  },
  mounted() {
    this.opening();
    InView.InView();
    carousels.carouselSingle();
    carousels.carouselSingleThumbnail();
    carousels.carouselCentering();
    carousels.carouselSideScrolling();
    carousels.carouselCover();
  },
  methods: {
    opening() {
      this.isOpen = true;
      this.$nextTick(() => {
        this.$refs.heromovie.play();
      });
    },
    handleScroll: function (evt, el) {
      if (window.scrollY > 300) {
        el.classList.remove("isPageTop");
      } else {
        el.classList.add("isPageTop");
      }
    },
  },
});
